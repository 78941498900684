import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoHeader from "../../assets/img/logo-header.svg";

const Header = ({ bg }) => {
  // Read UTM parameters if present
  const query = new URLSearchParams(window.location.search);
  const utm_source = query.get("utm_source");
  const utm_medium = query.get("utm_medium");

  let utms = "";
  if (utm_source && utm_medium) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  // Current route/path
  let search = window.location.pathname;

  return (
    <Navbar expand="lg" className={`header ${bg} p-md-0 p-4 pb-md-4 pb-4 pt-md-4`} variant="light">
      <Container>
        {/* Brand / Logo */}
        <Navbar.Brand as={Link} to={`/${utms}`}>
          <img
            src={logoHeader}
            alt="Montironi Usados"
            style={{ maxHeight: 50 }} // Optional styling
          />
        </Navbar.Brand>

        {/* Toggle button for mobile */}
        <Navbar.Toggle aria-controls="navbar-content" />

        {/* Collapsible content */}
        <Navbar.Collapse id="navbar-content ">
          <Nav className="ms-auto pt-4 pt-md-0 text-center">
            {/* "Quiero Comprar" link */}
            <div>
              <Nav.Link
                as={Link}
                to={`/${utms}`}
                className={search === "/" ? "btn btnBuscar mx-2 mt-3" : "p-4 linkhsimple mx-2"}
              >
                Quiero Comprar
              </Nav.Link>
            </div>

            {/* "Vendé tu usado" link */}
            <div>
              <Nav.Link
                as={Link}
                to={`/vender${utms}`}
                className={search === "/vender" ? "btn btnBuscar mx-2 mt-3" : "p-4 linkhsimple mx-2"}
              >
                Vendé tu usado
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
