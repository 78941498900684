import { Accordion, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Steps from "./Steps";
import { useState } from "react";
import { VehiculosContext } from "../../context/VehiculosContext";
import { useContext } from "react";
import alertCircle from "../../assets/img/alert-circle.png";
import unselected from "../../assets/img/unselected.png";
import checkCircle from "../../assets/img/check-circle.png";
import Select from "react-select";
import { useRef } from "react";
import ModalFotos from "./ModalFotos";
import { useEffect } from "react";
import { marcasVehiculos } from "../../config/marcas";
import emailjs from "emailjs-com";
import loading from "../../assets/img/loading.svg";
import checkOk from "../../assets/img/checkOk.svg";
import warningIcon from "../../assets/img/warning.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Vender = () => {
  const { cotizador, setCotizador } = useContext(VehiculosContext);
  const [modalShow, setModalShow] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [sending, setSending] = useState(false);
  const [sended, setSended] = useState(false);
  const [errorOnSend, setErrorOnSend] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  //FOR STEPS
  const [step0Completed, setStep0Completed] = useState(false);
  const [step1Completed, setStep1Completed] = useState(false);
  const [step2Completed, setStep2Completed] = useState(false);
  const [step3Completed, setStep3Completed] = useState(false);

  //REF FOR ACCORDIONS
  const accordionRef0 = useRef(null);
  const accordionRef1 = useRef(null);
  const accordionRef2 = useRef(null);
  const accordionRef3 = useRef(null);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_verde_vender",
      cancelButton: "btn btn_verde_vender",
    },
    buttonsStyling: false,
  });

  //ABRO EL PRIMER PASO AL INGRESAR
  useEffect(() => {
    !sending && !sended && !errorOnSend && accordionRef0.current.click();
  }, []);

  //SETCOMPLETED STEP BY ANYCHANGES IN COTIZADOR STATE
  useEffect(() => {
    if (cotizador.year && cotizador.km) {
      setStep0Completed(true);
    }

    if (!cotizador.year || !cotizador.km) {
      setStep0Completed(false);
    }

    if (cotizador.marca && cotizador.modelo && cotizador.version) {
      setStep1Completed(true);
    }

    if (!cotizador.marca || !cotizador.modelo || !cotizador.version) {
      setStep1Completed(false);
    }

    if (
      cotizador.pic_frente &&
      cotizador.pic_izquierda &&
      cotizador.pic_derecha &&
      cotizador.pic_atras &&
      cotizador.pic_tablero &&
      cotizador.pic_interior_frente &&
      cotizador.pic_interior_atras &&
      cotizador.pic_neumaticos_1 &&
      cotizador.pic_neumaticos_2 &&
      cotizador.pic_neumaticos_3 &&
      cotizador.pic_neumaticos_4
    ) {
      setStep2Completed(true);
    }

    if (
      !cotizador.pic_frente ||
      !cotizador.pic_izquierda ||
      !cotizador.pic_derecha ||
      !cotizador.pic_atras ||
      !cotizador.pic_tablero ||
      !cotizador.pic_interior_frente ||
      !cotizador.pic_interior_atras ||
      !cotizador.pic_neumaticos_1 ||
      !cotizador.pic_neumaticos_2 ||
      !cotizador.pic_neumaticos_3 ||
      !cotizador.pic_neumaticos_4
    ) {
      setStep2Completed(false);
    }

    if (cotizador.nombre && cotizador.email && cotizador.telefono) {
      setStep3Completed(true);
    }

    if (!cotizador.nombre || !cotizador.email || !cotizador.telefono) {
      setStep3Completed(false);
    }
  }, [cotizador]);

  //HANDLE STEPS
  const handleStep = (e) => {
    setActiveStep(e);

    if (e === 0) {
      accordionRef0.current.click();
    }
    if (e === 1) {
      accordionRef1.current.click();
    }
    if (e === 2) {
      accordionRef2.current.click();
    }
    if (e === 3) {
      accordionRef3.current.click();
    }
  };

  //MANEJO LOS CAMBIOS EN EL FORM
  const handleChange = (e) => {
    setCotizador({ ...cotizador, [e.target.name]: e.target.value });
  };

  //VALIDO LOS KMS
  const updateNumber = (e) => {
    if (e.target.validity.valid && e.target.value <= 150000) {
      handleChange(e);
    } else {
      swalWithBootstrapButtons.fire({
        title: "Ingrese solo números y hasta 150000km.",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Volver",
      });
      setCotizador({ ...cotizador, km: "" });
    }
  };

  //VALIDATE EMAIL
  const validateEmail = (e) => {
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }

    if (isValidEmail(e.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }

    handleChange(e);
  };

  //generate years
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  //TOGGLE DE ACCORDION
  const CustomToggle = ({ title, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);

    let reference;

    if (eventKey === "0") {
      reference = accordionRef0;
    }
    if (eventKey === "1") {
      reference = accordionRef1;
    }
    if (eventKey === "2") {
      reference = accordionRef2;
    }
    if (eventKey === "3") {
      reference = accordionRef3;
    }

    return (
      <div
        className="d-flex justify-content-start align-content-center align-items-center titleCardVender "
        onClick={() => {
          decoratedOnClick(eventKey);
          handleStep(Number(eventKey));
        }}
        ref={reference}
        style={{ pointerEvents: `${activeStep >= Number(eventKey) ? "" : "none"}` }}
      >
        <div>
          {eventKey === "0" ? (
            <img src={!cotizador.year || !cotizador.km ? unselected : checkCircle} alt="Alert" />
          ) : null}
          {eventKey === "1" ? (
            <img
              src={!cotizador.marca || !cotizador.modelo || !cotizador.version ? unselected : checkCircle}
              alt="Alert"
            />
          ) : null}
          {eventKey === "2" ? (
            <img
              src={
                !cotizador.pic_frente ||
                !cotizador.pic_izquierda ||
                !cotizador.pic_derecha ||
                !cotizador.pic_atras ||
                !cotizador.pic_tablero ||
                !cotizador.pic_interior_frente ||
                !cotizador.pic_interior_atras ||
                !cotizador.pic_neumaticos_1 ||
                !cotizador.pic_neumaticos_2 ||
                !cotizador.pic_neumaticos_3 ||
                !cotizador.pic_neumaticos_4
                  ? unselected
                  : checkCircle
              }
              alt="Alert"
            />
          ) : null}
          {eventKey === "3" ? (
            <img
              src={!cotizador.nombre || !cotizador.email || !cotizador.telefono ? unselected : checkCircle}
              alt="Alert"
            />
          ) : null}
        </div>
        <p className="m-0 ps-2" dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    );
  };

  const submitForm = (e) => {
    setSending(true);
    emailjs
      .send("serviceGmail", "cotizadorUsados", cotizador, "user_EIC1ELQk7I3NPaFMEDa64")
      .then((response) => {
        setSending(false);
        setSended(true);

        setTimeout(() => {
          setSending(false);
          setSended(true);
          setErrorOnSend(false);
          setCotizador({
            ...cotizador,
            nombre: "",
            email: "",
            telefono: "",
            year: "",
            km: "",
            marca: "",
            modelo: "",
            version: "",
            pic_frente: "",
            pic_izquierda: "",
            pic_derecha: "",
            pic_atras: "",
            pic_tablero: "",
            pic_interior_frente: "",
            pic_interior_atras: "",
            pic_neumaticos_1: "",
            pic_neumaticos_2: "",
            pic_neumaticos_3: "",
            pic_neumaticos_4: "",
          });
        }, 1000);

        window.location.href = "/-Gracias-vender";
      })
      .catch((err) => {
        setSending(false);
        setSended(false);
        setErrorOnSend(true);
      });
  };

  return (
    <Container className="containerVender">
      <Row className="">
        <Col md={12}>
          <h1 className="mt-0 pt-3 text-center">Completá el formulario y cotizá tu usado.</h1>
        </Col>
      </Row>
      <Row>
        <Col md={8} xs={12} className="mx-auto">
          <Steps
            handleStep={handleStep}
            activeStep={activeStep}
            step0Completed={step0Completed}
            step1Completed={step1Completed}
            step2Completed={step2Completed}
            step3Completed={step3Completed}
          />

          {
            //SI NO ESTA ENVIANDO, NI HAY ERRORES NI SE ACABA DE ENVIAR EL FORM MOSTRAR EL FORM
            !sended && !sending && !errorOnSend && (
              <>
                <Accordion className="accordionVender">
                  <Card className="cursor-pointer">
                    <Card.Header>
                      <CustomToggle eventKey="0" title={"<b>Paso 1:</b> Año y Kilometraje"} />
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {" "}
                        <div className="d-flex flex-md-row flex-column justify-content-between align-content-center align-items-center ps-md-5 ps-0">
                          <div className="d-flex flex-md-row flex-column justify-content-start align-content-end align-items-end w-100-mobile">
                            <select name="year" className="form-control yearDesktop" onChange={handleChange}>
                              <option disabled selected value={null}>
                                Año
                              </option>
                              {range(currentYear, currentYear - 6, -1).map((y) => (
                                <option value={y}>{y}</option>
                              ))}
                            </select>
                            <div className="d-md-flex justify-content-start flex-column ps-md-3 ps-0 pt-3 pt-md-0 w-100-mobile divKm">
                              <label className="label_vender">
                                Kilometraje *{" "}
                                <span className="vender_label_extra">(solo números, máximo 150.000km)</span>
                              </label>
                              <input
                                name="km"
                                type="tel"
                                max="100000"
                                maxLength={6}
                                className="form-control"
                                placeholder="kilometraje"
                                pattern="^-?[0-9]\d*\.?\d*$"
                                value={cotizador.km}
                                onChange={(e) => updateNumber(e)}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-content-end align-items-end flex-column ps-md-3 ps-0 pt-5">
                            {" "}
                            <button
                              disabled={!cotizador.year || !cotizador.km ? true : false}
                              className={`vender_siguiente ${!cotizador.year || !cotizador.km ? "disabled" : ""}`}
                              onClick={() => {
                                handleStep(1);
                              }}
                            >
                              Siguiente paso >
                            </button>
                          </div>
                        </div>
                        <div className="ps-5 pt-2 d-flex justify-content-start align-content-center align-items-center">
                          <div>
                            <img src={alertCircle} alt="Alert" />
                          </div>
                          <p className="ps-2 m-0 pt-1 fs-12px">Tomamos modelos desde 2018 y con hasta 150.000km</p>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card className="cursor-pointer">
                    <Card.Header>
                      <CustomToggle eventKey="1" title={"<b>Paso 2:</b> Modelo y versión"} />
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        {" "}
                        <Container className="divModelo">
                          <Row className="pb-md-4 pb-2 flex-md-row flex-column">
                            <Col>
                              <div className="d-flex  justify-content-start flex-column ps-3 pt-md-4 pt-1">
                                <label className="label_vender">Marca del vehículo *</label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isSearchable
                                  name="marca"
                                  options={marcasVehiculos}
                                  placeholder="Escriba una marca..."
                                  onChange={(e) => handleChange({ target: { value: e.value, name: "marca" } })}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-start flex-column ps-3 pt-4">
                                <label className="label_vender">Modelo *</label>
                                <input
                                  name="modelo"
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  placeholder="Modelo"
                                  value={cotizador.modelo}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-start flex-column ps-3 pt-4">
                                <label className="label_vender">Versión *</label>
                                <input
                                  name="version"
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  placeholder="Modelo"
                                  value={cotizador.version}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex flex-md-row flex-column justify-content-center align-content-md-end align-items-md-end align-content-center align-items-center flex-column ps-md-3 pt-md-5 ps-0 pt-4">
                                <button
                                  disabled={!cotizador.marca || !cotizador.modelo || !cotizador.version ? true : false}
                                  className={`vender_siguiente ${
                                    !cotizador.marca || !cotizador.modelo || !cotizador.version ? "disabled" : ""
                                  }`}
                                  onClick={() => {
                                    handleStep(2);
                                  }}
                                >
                                  Siguiente paso >
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card className="cursor-pointer">
                    <Card.Header>
                      <CustomToggle eventKey="2" title={"<b>Paso 3:</b> Fotos de tu vehículo"} />
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <Container>
                          <Row className="pt-3 pb-4">
                            <Col md={8} xs={11}>
                              <p className="ps-4 pb-2">
                                Para poder hacer una mejor cotización, te pedimos que nos compartas fotos de tu
                                vehículo. <br></br> Te recomendamos seguir el instructivo paso a paso y capturar fotos
                                con tu celular.
                              </p>
                              <Button
                                variant="success"
                                type="button"
                                className="btn_verde_vender ms-4"
                                onClick={() => setModalShow(true)}
                              >
                                {cotizador.pic_frente &&
                                cotizador.pic_izquierda &&
                                cotizador.pic_derecha &&
                                cotizador.pic_atras &&
                                cotizador.pic_tablero &&
                                cotizador.pic_interior_frente &&
                                cotizador.pic_interior_atras &&
                                cotizador.pic_neumaticos_1 &&
                                cotizador.pic_neumaticos_2 &&
                                cotizador.pic_neumaticos_3 &&
                                cotizador.pic_neumaticos_4
                                  ? "Ver mis fotos cargadas"
                                  : "Comenzar"}
                              </Button>
                            </Col>

                            {cotizador.pic_frente &&
                            cotizador.pic_izquierda &&
                            cotizador.pic_derecha &&
                            cotizador.pic_atras &&
                            cotizador.pic_tablero &&
                            cotizador.pic_interior_frente &&
                            cotizador.pic_interior_atras &&
                            cotizador.pic_neumaticos_1 &&
                            cotizador.pic_neumaticos_2 &&
                            cotizador.pic_neumaticos_3 &&
                            cotizador.pic_neumaticos_4 ? (
                              <Col>
                                <div className="d-flex justify-content-center align-content-end align-items-end flex-column ps-3 pt-3">
                                  <button
                                    disabled={
                                      !cotizador.marca || !cotizador.modelo || !cotizador.version ? true : false
                                    }
                                    className={`vender_siguiente ${
                                      !cotizador.marca || !cotizador.modelo || !cotizador.version ? "disabled" : ""
                                    }`}
                                    onClick={() => {
                                      handleStep(3);
                                    }}
                                  >
                                    Siguiente paso >
                                  </button>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </Container>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card className="cursor-pointer">
                    <Card.Header>
                      <CustomToggle eventKey="3" title={"<b>Paso 4:</b> Tus datos de contacto"} />
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        {" "}
                        <Container>
                          <Row className="pb-4 flex-md-row flex-column">
                            <Col>
                              <div className="d-flex justify-content-start flex-column ps-3 pt-4 ">
                                <label className="label_vender">Nombre y apellido *</label>
                                <input
                                  name="nombre"
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  placeholder="Nombre"
                                  value={cotizador.nombre}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-start flex-column ps-3 pt-4 ">
                                <label className="label_vender">Email *</label>
                                <input
                                  name="email"
                                  type="email"
                                  maxLength={50}
                                  className={`form-control ${
                                    cotizador.email !== "" && !validEmail ? "invalid-input" : ""
                                  } `}
                                  placeholder="Email"
                                  value={cotizador.email}
                                  onChange={(e) => validateEmail(e)}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-start flex-column ps-3 pt-4 ">
                                <label className="label_vender">Teléfono *</label>
                                <input
                                  name="telefono"
                                  type="text"
                                  maxLength={50}
                                  className="form-control"
                                  placeholder="Telefono"
                                  value={cotizador.telefono}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-center align-content-end align-items-end flex-column ps-3 pt-5">
                                {/* <button
                            disabled={!cotizador.nombre || !cotizador.email || !cotizador.telefono ? true : false}
                            className={`vender_siguiente ${
                              !cotizador.nombre || !cotizador.email || !cotizador.telefono ? "disabled" : ""
                            }`}
                            onClick={() => {
                              handleStep(5);
                            }}
                          >
                            Siguiente >
                          </button> */}
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>

                {step0Completed && step1Completed && step2Completed && step3Completed && validEmail && (
                  <div className="d-flex flex-md-row flex-column justify-content-around align-content-center align-items-center pt-5">
                    <div className="p-md-0 p-4">
                      <div className="d-flex justify-content-start align-content-center align-items-center pb-2">
                        <div>
                          <img src={checkCircle} alt="Alert" />
                        </div>

                        <p className="m-0 vender_text_green ps-2">Ya casi finalizamos. </p>
                      </div>
                      <p>
                        Si pudiste completar todos los pasos, envianos tu formulario, <br></br> revisaremos tu
                        cotización y nos comunicaremos con vos a la brevedad.{" "}
                      </p>
                    </div>
                    <div className="pb-md-0 pb-5">
                      <Button
                        variant="success"
                        type="button"
                        className="btn_verde_vender ms-4"
                        onClick={() => submitForm()}
                      >
                        Enviar formulario >
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )
          }

          {
            // SI ESTA ENVIANDO EL FORM
            !sended && sending && !errorOnSend && (
              <Container>
                <Row>
                  <Col className="pt-5 pb-5">
                    <div className="w-100 d-flex justify-content-center align-content-center align-items-center loading_box">
                      <img src={loading} alt="Cargando" style={{ width: "64px" }} />
                    </div>
                    <p className="desc_moda_vender_paso_2 text-center">Estamos enviando tu solicitud...</p>
                  </Col>
                </Row>
              </Container>
            )
          }

          {
            // SI SE ENVIO CORRECTAMENTE EL FORM
            sended && !sending && !errorOnSend && (
              <Container>
                <Row>
                  <Col className="pt-5 pb-5">
                    <div className="w-100 d-flex justify-content-center align-content-center align-items-center loading_box">
                      <img src={checkOk} alt="Enviado" style={{ width: "64px" }} />
                    </div>
                    <p className="desc_moda_vender_paso_2 text-center fw-bold"> Gracias por enviarnos tu solicitud. </p>
                    <p className="desc_moda_vender_paso_2 text-center pt-1">
                      Nos pondremos en contacto lo antes posible.
                    </p>
                    <div className="d-flex justify-content-center align-content-center align-items-center pt-4">
                      <div>
                        <button
                          className={"vender_siguiente"}
                          onClick={() => {
                            window.location.href = "/";
                          }}
                        >
                          Volver al home
                        </button>
                      </div>
                      <div>
                        <Button
                          variant="success"
                          type="button"
                          className="btn_verde_vender ms-4"
                          onClick={() => window.location.reload()}
                        >
                          Cargar otro vehículo
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )
          }

          {
            // SI HUBO UN ERROR AL ENVIAR EL FORM
            !sended && !sending && errorOnSend && (
              <Container>
                <Row>
                  <Col className="pt-5 pb-5">
                    <div className="w-100 d-flex justify-content-center align-content-center align-items-center loading_box">
                      <img src={warningIcon} alt="Enviado" style={{ width: "64px" }} />
                    </div>
                    <p className="desc_moda_vender_paso_2 text-center fw-bold">
                      Se ha producido un error al enviar el formulario{" "}
                    </p>

                    <div className="d-flex justify-content-center align-content-center align-items-center pt-4">
                      <div>
                        <Button
                          variant="success"
                          type="button"
                          className="btn_verde_vender ms-4"
                          onClick={() => submitForm()}
                        >
                          Volver a intentarlo
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )
          }
        </Col>
      </Row>
      <ModalFotos show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
};

export default Vender;
