import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoFooter from "../../assets/img/logoFooter.png";
import wspicon from "../../assets/img/footer/whatsapp.png";
import mailicon from "../../assets/img/footer/mail.png";
import fbicon from "../../assets/img/footer/facebook.png";
import igicon from "../../assets/img/footer/instagram.png";
import locationicon from "../../assets/img/footer/ubicacion.png";

const Footer = () => {
  const query = new URLSearchParams(window.location.search);
  const utm_source = query && query.get("utm_source");
  const utm_medium = query && query.get("utm_medium");

  let utms = "";
  if (utm_source !== null && utm_medium !== null) {
    utms = `?utm_source=${utm_source}&utm_medium=${utm_medium}`;
  }

  return (
    <>
      <Container fluid className={`header bgDark`} style={{ zIndex: "2" }}>
        <Container>
          <Row className="pt-4 pb-4">
            <Col
              md={3}
              xs={11}
              className="text-center d-flex justify-content-start align-content-start  mx-auto pb-md-0 pb-4"
            >
              <div>
                <Link to={`/${utms}`}>
                  <img src={logoFooter} alt="Montironi Usados" />
                </Link>
                <Row>
                  <Col className="pt-3 d-flex">
                    <div className="pe-3">
                      <a href="https://www.facebook.com/montironiusados" className="" target="_blank" rel="noreferrer">
                        <img src={fbicon} />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.instagram.com/montironi.usados/"
                        className=""
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={igicon} />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={9} xs={11} className="text-center newFooter mx-auto">
              <Row>
                <Col md={3} xs={12} className="pb-md-0 pb-4">
                  <h5 className="pb-1">Información</h5>
                  <ul className="list-unstyled">
                    <li className="text-start pb-2">
                      <Link to={`/${utms}`}>Comprá tu usado</Link>
                    </li>
                    <li className="text-start">
                      <Link to={`/vender${utms}`}>Vendé tu usado</Link>
                    </li>
                  </ul>
                </Col>
                <Col md={4} xs={12} className="pb-md-0 pb-4">
                  <h5>Contacto</h5>
                  <ul className="list-unstyled">
                    <li className="text-start pb-2">
                      <div className="d-flex justify-content-start">
                        <div className="pe-2">
                          <img src={wspicon} />
                        </div>
                        <div className="">
                          <a
                            href="https://api.whatsapp.com/send?phone=+5493517915736&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n"
                            target="_blank"
                            rel="noreferrer"
                          >
                            +54 9 351 791-5736
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="text-start">
                      <div className="d-flex justify-content-start">
                        <div className="pe-2">
                          <img src={mailicon} />
                        </div>
                        <div className="">
                          <a href="mailto:usados@montironi.com" target="_blank" rel="noreferrer">
                            usados@montironi.com
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={5} xs={12}>
                  <h5>Sucursales</h5>
                  <ul className="list-unstyled">
                    <li className="text-start pb-2">
                      <div className="d-flex justify-content-start">
                        <div className="pe-2">
                          <img src={locationicon} />
                        </div>
                        <div className="">
                          <a href="https://maps.app.goo.gl/cGjzGw2H58SMqHBE7" target="_blank" rel="noreferrer">
                            Av. Monseñor Pablo Cabrera 4935, Córdoba
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="text-start">
                      <div className="d-flex justify-content-start">
                        <div className="pe-2">
                          <img src={locationicon} />
                        </div>
                        <div className="">
                          <a href="https://maps.app.goo.gl/p5RHRLGwsTp3j1xs8" target="_blank" rel="noreferrer">
                            La Cordillera 3697, Córdoba
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ backgroundColor: "#3AAA35" }}>
        <Container>
          <Row>
            <Col>
              <p className="footerText pt-3 pb-3 mb-0">
                Al navegar en el sitio tené en cuenta que los precios y ofertas disponibles no son vinculantes y pueden
                sufrir modificaciones sin previo aviso. <br /> Todas las fotos son a modo ilustrativo. Copyright © 2025
                Montironi Usados. Todos los derechos reservados
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
